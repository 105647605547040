import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentLocation } from '@src/reducers/locations';
import { getCreditsMetadata } from '@src/reducers/microcredits';
import { useSeoState } from '@src/reducers/seo';

import { getHeadingText, getSubHeadingText } from './helpers';

interface IParams {
  defaultHeading?: string;
  defaultSubHeading?: string;
}

export const useHeadingTexts = ({ defaultHeading = 'Займы', defaultSubHeading }: IParams | undefined = {}) => {
  const { seo } = useSeoState();
  const currentLocation = useCurrentLocation();
  const meta = useSelector(getCreditsMetadata);

  const { asPath, query } = useRouter();

  const headingText = useMemo(() => getHeadingText(seo, asPath, query), [seo, asPath, query]);

  const subHeadingText = useMemo<string | undefined | null>(
    () => getSubHeadingText(seo, meta, currentLocation, asPath, query),
    [seo, meta, currentLocation, asPath, query],
  );

  return {
    headingText: headingText || defaultHeading || '',
    subHeadingText: subHeadingText || defaultSubHeading,
  };
};
