import { useCallback, useEffect, useRef } from 'react';

// @ts-ignore
export const useDebounceCallback = (callback, time: number) => {
  const ref = useRef<number>();

  useEffect(() => () => clearTimeout(ref.current), []);

  return useCallback(
    (...args) => {
      clearTimeout(ref.current);
      ref.current = window.setTimeout(callback, time, ...args);
    },
    [callback, time],
  );
};
