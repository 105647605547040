import type { Seo } from '@sravni/types/lib/seo';

import type { List } from '@src/@types/microcredits';
import type { ILocation } from '@src/reducers/locations';
import { getSubheadingReplacements } from '@src/utils/meta';

import { getHeadingByRoute } from './getHeadingByRoute';

export const getSubHeadingText = (
  seo: Seo,
  meta: List.IMetadata,
  currentLocation: ILocation,
  asPath: string,
  query: Record<string, string | string[]>,
) => {
  const headingByRoute = getHeadingByRoute(asPath, query);
  if (headingByRoute) return headingByRoute.subHeading;

  if (!seo?.subHeading) return '';

  return getSubheadingReplacements({ template: seo.subHeading, meta, location: currentLocation });
};
