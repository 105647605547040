import cn from 'classnames';
import React, { memo } from 'react';

import { Layout } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import { Sort } from '@src/components/ProductList/components/Sort';
import type { FILTERS_NAMES } from '@src/constants/filters';
import { SORT_SOURCE_AB, SORT_SOURCE_UTM } from '@src/constants/filters';
import { useHasUtmLabel } from '@src/reducers/route';

import styles from './styles.module.scss';

type Props = {
  sort: string;
  onChange: (name: FILTERS_NAMES, value: string) => void;
  className?: string;
  hasSortLabel: boolean;
};

export const SortPanel = memo(({ sort, onChange, className, hasSortLabel }: Props) => {
  const isMobile = useIsMobile();
  const hasUtmLabel = useHasUtmLabel();

  const source = hasUtmLabel ? SORT_SOURCE_UTM : SORT_SOURCE_AB;

  if (isMobile) {
    return (
      <Sort
        value={sort}
        onChange={onChange}
        className={cn(styles.sortWrapper, className)}
        source={SORT_SOURCE_AB}
        hasSortLabel={hasSortLabel}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Layout className={styles.layout}>
        <Sort value={sort} onChange={onChange} source={source} hasSortLabel={hasSortLabel} />
      </Layout>
    </div>
  );
});
