import { useEffect, useState } from 'react';

import type { IClientMicrocredit, IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { fetchProduct } from '@src/services/product';

import { formatCardData } from '../helpers';

interface Props {
  visible: boolean;
  offer: IClientMicrocreditListItem;
}

export const useGetProduct = ({ visible, offer }: Props) => {
  const [data, setData] = useState<IClientMicrocredit>(null);

  useEffect(() => {
    if (!visible || !!data) {
      return;
    }

    fetchProduct(offer.organization.alias, offer.alias)
      .then((data) => {
        setData({
          advertising: offer.advertising,
          ...data,
        });
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, !!data]);

  return data || formatCardData(offer);
};
