import { useMemo } from 'react';

import { Dropdown, Icon, Radio } from '@sravni/react-design-system';
import type { SelectValue } from '@sravni/react-design-system/dist/types/components/Select/types/Select.types';
import { ArrowsSort } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import { SORT_LABELS, SORT_VALUES } from '@src/constants/filters';
import { sendSortOnDropdownClickEvent, sendSortOnDropdownOptionsClickEvent } from '@src/helpers/analyticsEvents';

import styles from './styles.module.scss';

export interface ISortItems {
  value: string;
  label: string;
}

interface ISortProps {
  className?: string;
  value?: string;
  source: ISortItems[];
  onChange?: (name: string, value: any) => void;
  name?: string;
  hasSortLabel: boolean;
}

const OPTION_LABELS: Record<string, string> = {
  [SORT_VALUES.POPULAR]: 'Популярные',
  [SORT_VALUES.RATE_MORE]: 'По ставке',
  [SORT_VALUES.APPROVAL_PROBABILITY]: 'По вероятности одобрения',
};

export const Sort = ({ className, value, onChange, name = 'sort', source, hasSortLabel }: ISortProps) => {
  const isMobile = useIsMobile();

  const handleItemClick = (sortValue: SelectValue) => {
    onChange?.(name, sortValue);
    sendSortOnDropdownOptionsClickEvent(SORT_LABELS[sortValue as string]);
  };

  const sortLabel = OPTION_LABELS[value || SORT_VALUES.ADVERTISING] || 'Сортировать';
  const { options, defaultOption } = useMemo(() => {
    const list = source.map((sourceItem) => ({ ...sourceItem, control: <Radio /> }));

    return {
      options: list,
      defaultOption: list[0].value,
    };
  }, [source]);

  if (!isMobile) {
    return (
      <div className={className}>
        <Dropdown
          onOpen={sendSortOnDropdownClickEvent}
          className={styles.container}
          options={options}
          value={value}
          defaultValue={defaultOption}
          placement="bottom-start"
          onChange={handleItemClick}
        >
          <div className={styles.dropdownWrapper}>
            <Icon icon={<ArrowsSort />} />
            {/* <SortArrowsIcon className={styles.icon} />*/}
            <div className={styles.text}>{sortLabel}</div>
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={className}>
      <Dropdown
        onOpen={sendSortOnDropdownClickEvent}
        className={styles.container}
        options={options}
        value={value}
        defaultValue={defaultOption}
        placement="bottom-start"
        onChange={handleItemClick}
      >
        <div className={styles.dropdownWrapper}>
          <Icon icon={<ArrowsSort />} />
          {hasSortLabel && <div className={styles.text}>{sortLabel}</div>}
        </div>
      </Dropdown>
    </div>
  );
};
