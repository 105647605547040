import type { Seo } from '@sravni/types/lib/seo';

import { getHeadingReplacements } from '@src/utils/meta';

import { getHeadingByRoute } from './getHeadingByRoute';

export const getHeadingText = (seo: Seo, asPath: string, query: Record<string, string | string[]>) => {
  const headingByRoute = getHeadingByRoute(asPath, query);
  if (headingByRoute) return headingByRoute.heading;

  if (seo?.heading) return seo.heading;

  if (!seo?.headingTemplate) return '';

  return getHeadingReplacements(seo.headingTemplate, seo);
};
