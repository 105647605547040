import React from 'react';

import { Banner } from '@sravni/react-advertisement';
import type { IBanner } from '@sravni/types/lib/banners';

import type * as Banners from '@src/@types/banners';
import { BANNER_LIST_POSITION_INDEX, BANNER_TYPE } from '@src/constants/banners';

interface IProps {
  index: number;
  listPosition?: number;
  banners: Record<string, Banners.IBanner>;
  startPosition?: number;
}

export function getBanners({
  index,
  banners,
  listPosition = BANNER_LIST_POSITION_INDEX,
  startPosition = BANNER_LIST_POSITION_INDEX,
}: IProps) {
  if (!index || (index - startPosition) % listPosition !== 0) {
    return undefined;
  }

  const bannerIndex = getBannerIndex(index, listPosition, startPosition);

  const banner = decorate(banners[BANNER_TYPE], bannerIndex) as IBanner;
  return banner ? <Banner banner={banner} lazy abName="" /> : null;
}

function decorate(banner: Banners.IBanner, bannerIndex: number) {
  if (!banner) {
    return null;
  }

  return {
    ...banner,
    params: { ...banner.params, order: bannerIndex, teg: BANNER_TYPE },
  };
}

function getBannerIndex(
  slotIndex: number,
  listPosition = BANNER_LIST_POSITION_INDEX,
  startPosition = BANNER_LIST_POSITION_INDEX,
) {
  return (slotIndex - startPosition) / listPosition + 1;
}
