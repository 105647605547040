import cn from 'classnames';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Layout } from '@sravni/react-design-system';

import { AnchorButton } from '@src/components/ProductList/components/AnchorButton';
import type { ListKeys } from '@src/constants/microcredits';
import { sendPaginationEvent } from '@src/helpers/analyticsEvents';
import { fetchMoreOffersAB } from '@src/reducers/microcredits';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
  listKey: ListKeys;
  preloadTotal: number;
  loadItemCount: number;
}

export const ShowMoreButton: React.FC<IProps> = memo(({ listKey, preloadTotal, className }) => {
  const dispatch = useDispatch();

  const handleClickLoadMore = useCallback(() => {
    sendPaginationEvent();
    dispatch(fetchMoreOffersAB({ key: listKey }));
  }, [dispatch, listKey]);

  return (
    <div className={cn(styles.container, className)}>
      <Layout className={styles.layout}>
        <AnchorButton text={`Показать еще ${preloadTotal}`} onClick={handleClickLoadMore} />
      </Layout>
    </div>
  );
});
