import cn from 'classnames';
import React, { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';
import type { IConfig } from '@sravni/types/lib/branding';

import { PageLocationHeader } from './LocationHeader';
import styles from './styles.module.scss';

interface IPageHeaderLocationProps {
  brandingConfig?: IConfig['creditCalculator'];
  className?: string;
  header: string;
  region?: string;
  size?: 'normal' | 'inherit';
  position?: 'left' | 'center';
}

export const PageHeaderLocation: React.FC<IPageHeaderLocationProps> = ({ className, brandingConfig, ...props }) => {
  const isMobile = useIsMobile();
  const componentStyles = useMemo(
    () =>
      ({
        '--branding-header-color': isMobile
          ? undefined
          : brandingConfig?.regionTextColor || brandingConfig?.mainTextColor,
      } as React.CSSProperties),
    [brandingConfig?.mainTextColor, brandingConfig?.regionTextColor, isMobile],
  );

  return (
    <div className={cn(styles.page_header_location_container, className)} style={componentStyles}>
      <PageLocationHeader {...props} className={styles.page_header_location} />
    </div>
  );
};
