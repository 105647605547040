import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setScrolledDown } from '@src/reducers/branding';

export const useShowBottomBannerOnScroll = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const dismissed = useRef<boolean>(false);

  const handleScroll = useCallback((): void => {
    if (scrollRef.current && !dismissed.current) {
      const isElementScrolledDown = scrollRef.current.getBoundingClientRect().bottom <= 0;
      if (isElementScrolledDown) {
        dispatch(setScrolledDown(true));
        dismissed.current = true;
      }
    }
  }, [dispatch]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return (): void => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return { scrollRef };
};
