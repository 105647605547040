import has from 'lodash/has';

import { isKZContextPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { getCRMSpecialHeaderText } from './getCRMSpecialHeaderText';

const SPECIAL_PAGE_HEADING = 'Займы онлайн от лучших МФО';
const KZ_CONTEXT_PAGE_HEADING = 'Займы в Казахстане';

export const getHeadingByRoute = (route: string, query: Record<string, string | string[]>) => {
  const isSpecial = isSpecialPageWithoutQuery(route);
  const isCRMSpecial = isSpecial && has(query, 'landing_type');
  const isKZContext = isKZContextPage(route);

  if (isKZContext) {
    return { heading: KZ_CONTEXT_PAGE_HEADING, subHeading: '' };
  }

  if (isCRMSpecial) {
    return getCRMSpecialHeaderText(query?.landing_type as string);
  }

  if (isSpecial) {
    return {
      heading: SPECIAL_PAGE_HEADING,
      subHeading: '',
    };
  }
};
