interface INodeIndexProps {
  className?: string;
}

export const NoIndex: React.FC<INodeIndexProps> = ({ className, children }) => (
  <span className={className}>
    <span dangerouslySetInnerHTML={{ __html: '<!--noindex-->' }} />
    {children}
    <span dangerouslySetInnerHTML={{ __html: '<!--/noindex-->' }} />
  </span>
);
