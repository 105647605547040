import cn from 'classnames';
import React, { memo } from 'react';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
}

export const PageHeaderBrandingContainer: React.FC<IProps> = memo(({ className, children }) => (
  <div className={cn(styles.container, className)}>{children}</div>
));
