import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import React, { memo, useCallback } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { AffSub4 } from '@src/@types/microcredits';
import { NoIndex } from '@src/components/NoIndex';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { sendSponsorClickEvent } from '@src/helpers/analyticsEvents';
import { usePixel } from '@src/hooks/usePixel';
import { useShowBottomBannerOnScroll } from '@src/hooks/useShowBottomBannerOnScroll';
import { useBranding, usePixelsBranding } from '@src/reducers/branding';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
}

export const PageHeaderSponsorLink: React.FC<IProps> = memo(({ className }) => {
  const isMobile = useIsMobile();
  const pixels = usePixelsBranding();
  const { scrollRef } = useShowBottomBannerOnScroll();
  const { organization } = useBranding() || {};

  const affSub4: AffSub4 = {
    organizationName: organization?.name ?? '',
    productName: 'Спонсор раздела',
  };

  const pixelsClick = usePixel(pixels?.click, {
    pixelType: PIXELS_TYPES.CLICK,
    affSub4,
  });
  const pixelsDisplay = usePixel(pixels?.display, { affSub4 });
  const pixelsTracking = usePixel(pixels?.tracking, { affSub4 });

  const handleSponsorClick = useCallback(() => {
    sendSponsorClickEvent(organization?.name);
  }, [organization?.name]);

  return (
    <NoIndex className={cn(styles.no_index, className)}>
      <a
        href={pixelsClick || ''}
        onClick={handleSponsorClick}
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {pixelsDisplay ? <img alt="pixelsDisplay" className={styles.pixel} src={pixelsDisplay} /> : null}
        {pixelsTracking ? <img alt="pixelsTracking" className={styles.pixel} src={pixelsTracking} /> : null}
      </a>
    </NoIndex>
  );
});
