import cn from 'classnames';
import React, { memo } from 'react';

import { Button } from '@sravni/react-design-system';

import styles from './styles.module.scss';

interface IProps {
  text?: string;
  onClick?: () => void;
}

export const AnchorButton: React.FunctionComponent<IProps> = memo((props: IProps) => {
  const { text = 'Показать еще', onClick } = props;

  return (
    <Button className={styles.button} variant="outlined" onClick={onClick} block>
      {text}
    </Button>
  );
});
