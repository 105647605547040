import { useRef } from 'react';

/**
 * Задача: https://sravni-corp.atlassian.net/browse/MFO-838
 * Здесь находятся функции для открытия модального окна по id карточки,
 * Это нужно чтобы избежать дублирования карточек
 */
export const useCardsWithModals = () => {
  const detailModals = useRef<Record<string, () => void>>({});

  return detailModals;
};
