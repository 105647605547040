import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type * as Microcredits from '@src/@types/microcredits';
import { DEFAULT_FILTERS, FILTERS_NAMES, MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { useCurrentLocation } from '@src/reducers/locations';
import { useSelectCreditsFilters } from '@src/reducers/microcredits';

import { useQueryReplace } from './useQueryReplace';

const DIFFERENCE_SET = new Set([
  FILTERS_NAMES.SECURITY,
  FILTERS_NAMES.GUARANTEE,
  FILTERS_NAMES.OBTAINING_METHOD,
  FILTERS_NAMES.CURRENCY,
  FILTERS_NAMES.PROLONGATION,
  FILTERS_NAMES.POSTPONEMENT,
  FILTERS_NAMES.BANKS,
]);

// @ts-ignore
const findDifferenceCount = (filters, defaultsFilters) =>
  Object.values(FILTERS_NAMES).reduce(
    (count, key) => (DIFFERENCE_SET.has(key) && !_isEqual(filters[key], defaultsFilters[key]) ? count + 1 : count),
    0,
  );

interface IParams {
  name?: ListKeys;
  defaultValue?: Partial<Microcredits.IFilters>;
}

export type IFilter = ReturnType<typeof useFilter>;

export const useFilter = (params: IParams = {}) => {
  const { name = ListKeys.LIST, defaultValue = DEFAULT_FILTERS[MODES.DEFAULT] } = params || {};

  const currentLocation = useCurrentLocation();
  const filters = useSelectCreditsFilters(name);
  const [filtersState, setFiltersState] = useState(_omit(filters, ['metadata']));

  useEffect(() => {
    setFiltersState(_omit(filters, ['metadata']));
  }, [filters]);

  const difference = useMemo(() => findDifferenceCount(filtersState, defaultValue), [defaultValue, filtersState]);
  const defaultFilters = useMemo(() => ({ ...defaultValue, [FILTERS_NAMES.LOCATION]: currentLocation.route }), [
    currentLocation.route,
    defaultValue,
  ]);
  const updateQuery = useQueryReplace();

  const setFilter = useCallback(
    (key: string, value: any) => {
      setFiltersState((original) => {
        // @ts-ignore
        if (original[key] !== value) {
          const newFilters = { ...original, [key]: value };

          updateQuery({
            newQuery: newFilters,
            defaultQuery: defaultFilters,
            key: name,
          });

          return newFilters;
        }

        return original;
      });
    },
    [updateQuery, defaultFilters, name],
  );

  const resetFilters = (): void => {
    if (!_isEqual(defaultFilters, filtersState)) {
      setFiltersState(defaultFilters);

      updateQuery({
        newQuery: defaultFilters,
        defaultQuery: defaultFilters,
        key: name,
      });
    }
  };

  return {
    filters: filtersState,
    setFilter,
    difference,
    resetFilters,
  };
};
